import { fullUrl } from 'scripts/fullUrl'
import { getDeviceApprovalRequestSettingResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'

export const getDeviceApprovalRequestSetting = async (params: {
  subdomain: string
}) => {
  const res = await axiosClient.get<getDeviceApprovalRequestSettingResponse>(
    `${fullUrl('api')}/teams/device_approval_request_setting?subdomain=${
      params.subdomain
    }`,
  )
  return res
}
