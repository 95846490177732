import React from 'react'
import {
  getDeviceApprovalRequestSettingResponse,
  DeviceApprovalRequestItem,
  SelectDeviceApprovalRequestItem,
  FormState,
} from 'types/ApiResponse'
import { useTranslation } from 'react-i18next'

interface Props {
  deviceApprovalRequestSetting: getDeviceApprovalRequestSettingResponse
  inputStatus: string // statusが取りうる値を列挙
  label: string
  backend_key: keyof getDeviceApprovalRequestSettingResponse['device_approval_request_form_setting']
  fieldName: keyof FormState
  value: string
  handleChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    field_name: string,
  ) => void
  errors: {
    [key: string]: {
      message?: string
    }
  }
}
export const SelectFormElement = ({
  deviceApprovalRequestSetting,
  inputStatus,
  label,
  backend_key,
  fieldName,
  value,
  handleChange,
  errors,
}: Props) => {
  const { t } = useTranslation()

  const isSelectDeviceApprovalRequestItem = (
    item: DeviceApprovalRequestItem,
  ): item is SelectDeviceApprovalRequestItem => {
    return item.field_type === 'select'
  }

  const formSetting =
    deviceApprovalRequestSetting?.device_approval_request_form_setting

  const selectedItem = formSetting?.[
    backend_key
  ] as SelectDeviceApprovalRequestItem

  if (selectedItem.visible !== true) {
    return <></>
  }

  return (
    <div className="w-full px-6 pb-3">
      <p className="text-xs font-semibold text-gray-700 pb-1">
        {t(label)} {selectedItem.required ? `(${t('必須')})` : <></>}
      </p>
      {inputStatus === 'inputIdentity' ? (
        <>
          <label>
            <select
              className="rounded px-2 w-full h-8 border border-black"
              data-test={fieldName}
              name={fieldName as string}
              onChange={(e) => handleChange(e, selectedItem.field_type)}
              value={value}
            >
              <option value="">選択してください</option>
              {isSelectDeviceApprovalRequestItem(formSetting?.[backend_key]) &&
                selectedItem.options.map(
                  (d: { value: string; label: string }) => (
                    <option key={d.value} value={d.value}>
                      {t(d.label)}
                    </option>
                  ),
                )}
            </select>
          </label>
          <p className="text-xs text-red-600">{errors?.[fieldName]?.message}</p>
        </>
      ) : (
        <p className="text-xs ml-3">
          {isSelectDeviceApprovalRequestItem(formSetting?.[backend_key]) &&
            selectedItem.options
              .filter(
                (d: { value: string; label: string }) => d.value === value,
              )
              .map((d: { label: string }) => d.label)[0]}
        </p>
      )}
    </div>
  )
}
