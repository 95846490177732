import React from 'react'
import {
  getDeviceApprovalRequestSettingResponse,
  FormState,
} from 'types/ApiResponse'
import { useTranslation } from 'react-i18next'

interface Props {
  deviceApprovalRequestSetting: getDeviceApprovalRequestSettingResponse
  inputStatus: string // statusが取りうる値を列挙
  backend_key: keyof getDeviceApprovalRequestSettingResponse['device_approval_request_form_setting']
  label: string
  fieldName: keyof FormState
  attachment?: File | null
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field_name: string,
  ) => void
  errors: {
    [key: string]: {
      message?: string
    }
  }
}

export const AttachmentFormElement = ({
  deviceApprovalRequestSetting,
  inputStatus,
  backend_key,
  label,
  fieldName,
  attachment,
  handleChange,
  errors,
}: Props) => {
  const { t } = useTranslation()

  const formSetting =
    deviceApprovalRequestSetting?.device_approval_request_form_setting

  if (formSetting?.[backend_key]?.field_type !== 'file') {
    return <></>
  }

  if (
    formSetting?.[backend_key as keyof typeof formSetting]?.visible !== true
  ) {
    return <></>
  }

  return (
    <div className="w-full px-6 pb-3">
      <p className="text-xs font-semibold text-gray-700 pb-1">
        {t(label)}{' '}
        {formSetting?.[backend_key]?.required ? `(${t('必須')})` : <></>}
      </p>
      {inputStatus === 'inputIdentity' ? (
        <>
          <input
            className=""
            style={{ fontSize: '14px' }}
            data-test={fieldName}
            type="file"
            name={fieldName as string}
            onChange={(e) =>
              handleChange(e, formSetting?.[backend_key]?.field_type)
            }
          />
          <p className="text-xs text-red-600">{errors?.[fieldName]?.message}</p>
        </>
      ) : (
        <p className="text-xs ml-3">
          {attachment ? attachment.name : '添付なし'}
        </p>
      )}
    </div>
  )
}
