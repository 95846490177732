import React, { useEffect, useState } from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useTranslation } from 'react-i18next'
import { StatusView } from './index/statusView'
import { InputUserEmail } from './index/inputUserEmail'
import { InputStatus } from './index/InputStatus'
import { InputUserIdentity } from './index/inputUserIdentity'
import { useIpAddress } from 'api/useIpAddress'
import axios from 'axios'
import { cookies } from 'scripts/cookies'
import { LoginPingResponse } from 'types/ApiResponse'
import { fullUrl } from 'scripts/fullUrl'
import { Loading } from 'presentation/components/Loading'
import { getDeviceApprovalRequestSettingResponse } from 'types/ApiResponse'
import { getDeviceApprovalRequestSetting } from 'api/getDeviceApprovalRequestSetting'
import { getSubdomain } from 'scripts/getSubdomainForHost'

export const DeviceApprovalRequest = () => {
  const { t } = useTranslation()
  const [inputStatus, setInputStatus] = useState<InputStatus>('inputEmail')
  const [{ ipAddress }] = useIpAddress()
  const [deviceSelfId, setDeviceSelfId] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [deviceApprovalRequestSetting, setDeviceApprovalRequestSetting] =
    useState<getDeviceApprovalRequestSettingResponse | null>(null)
  const [subdomain, setSubdomain] = useState('')
  const [sessionToken, setSessionToken] = useState('')

  useEffect(() => {
    // HACK: useEffectはwindow見つからないことはないはず。
    const subdomain = getSubdomain(window.location.host)
    setSubdomain(subdomain)

    const sessionTokenFromCookie = cookies.get('session_token') as string
    setSessionToken(sessionTokenFromCookie)
  })

  useEffect(() => {
    ;(async () => {
      if (!subdomain) {
        // 初期設定のロードのため、subdomainが取れたときは取る
        setLoading(false)
        return
      }
      const res = await getDeviceApprovalRequestSetting({ subdomain })
      if (res.status === 200) {
        const { data } = res
        setDeviceApprovalRequestSetting(data)
      }

      if (!sessionToken || inputStatus !== 'inputEmail') {
        // ユーザーのログイン状況に合わせて、初期値のinputEmailを、inputIdentityに変更する
        // ステップ1にいないときは何もしない
        setLoading(false)
        return
      }

      await axios
        .get<LoginPingResponse>(`${fullUrl('api')}/users/login/ping`, {
          params: { session_token: sessionToken },
        })
        .then((response) => {
          const { valid, device_self_id } = response.data
          if (valid) {
            setInputStatus('inputIdentity') // ログイン済みでかつ、step1にいを対象とした処理
            setDeviceSelfId(device_self_id) // スキップすると申請時に必要なdevice_self_idが取れないので、ここで設定しておく
          }
        })
        .finally(() => {
          setLoading(false)
        })
    })()
  }, [subdomain, sessionToken, inputStatus])

  const text = (inputStatus: InputStatus) => {
    if (inputStatus === 'inputEmail') {
      return 'ログインできない場合、端末の承認を管理者にリクエストすることができます。'
    } else if (inputStatus === 'inputIdentity') {
      return 'リクエストが本人のものであるか確認のため、本人確認情報を入力してください。'
    } else if (inputStatus === 'submit') {
      return '以下の内容で管理者に端末の承認リクエストを送信します。入力内容に誤りがないか確認して下さい。'
    } else if (inputStatus === 'success') {
      return 'リクエストが完了しました。管理者が承認するまでお待ちください。'
    } else {
      return ''
    }
  }

  const dataTestSuccess =
    inputStatus === 'success' ? { 'data-test': 'success' } : {}

  if (!loading) {
    return (
      <Layout>
        <p className="text-xl" {...dataTestSuccess}>
          {t('端末の承認リクエスト')}
        </p>
        <p
          className="px-6 mb-6 text-sm"
          style={{ textAlign: 'center', marginTop: '10px', color: '#333333' }}
        >
          {t(text(inputStatus))}
        </p>
        {inputStatus === 'success' ? (
          <div className="w-full h-20"></div>
        ) : (
          <StatusView status={inputStatus} />
        )}
        {(() => {
          if (inputStatus === 'inputEmail') {
            return (
              <InputUserEmail
                setStatus={setInputStatus}
                setDeviceSelfId={setDeviceSelfId}
              />
            )
          } else if (inputStatus === 'inputIdentity') {
            return (
              <InputUserIdentity
                status={inputStatus}
                setStatus={setInputStatus}
                ipAddress={ipAddress}
                deviceSelfId={deviceSelfId}
                deviceApprovalRequestSetting={deviceApprovalRequestSetting}
              />
            )
          } else if (inputStatus === 'submit') {
            return (
              <InputUserIdentity
                status={inputStatus}
                setStatus={setInputStatus}
                ipAddress={ipAddress}
                deviceSelfId={deviceSelfId}
                deviceApprovalRequestSetting={deviceApprovalRequestSetting}
              />
            )
          } else if (inputStatus === 'success') {
            return <></>
          } else {
            return <></>
          }
        })()}
        <div className="mt-2 mb-8">
          <a
            className="locked-help-link"
            href="https://www.notion.so/onetapincjp/81a7d45064ee4e4cb95e3bb6ea07cfbe"
          >
            {t('端末の承認リクエストについて')}
          </a>
        </div>
      </Layout>
    )
  }

  return (
    <main className="w-full h-full flex justify-center items-center">
      <Loading></Loading>
    </main>
  )
}
