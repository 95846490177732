import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputStatus } from '../InputStatus'
interface Props {
  status: InputStatus
}
export const StatusView = ({ status }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex-col w-full px-6 mb-6">
        <div className="flex items-center">
          <div
            style={
              status === 'inputEmail'
                ? { background: '#4c5cb6' }
                : { background: '#bdbdbd' }
            }
            className="rounded-full w-4 h-4 flex justify-center text-center items-center text-white text-sm"
          >
            1
          </div>
          <div className="text-sm ml-1" style={{ color: '#333333' }}>
            {t('アカウント情報を入力')}
          </div>
        </div>
        <div
          style={{ borderLeft: '1px solid #bdbdbd', marginLeft: 8 }}
          className="h-3 my-1"
        />
        <div className="flex items-center">
          <div
            style={
              status === 'inputIdentity'
                ? { background: '#4c5cb6' }
                : { background: '#bdbdbd' }
            }
            className="rounded-full w-4 h-4 flex justify-center text-center items-center text-white text-sm"
          >
            2
          </div>
          <div className="text-sm  ml-1" style={{ color: '#333333' }}>
            {t('本人確認情報を入力')}
          </div>
        </div>
        <div
          style={{ borderLeft: '1px solid #bdbdbd', marginLeft: 8 }}
          className="h-3 my-1"
        />
        <div className="flex items-center">
          <div
            style={
              status === 'submit'
                ? { background: '#4c5cb6' }
                : { background: '#bdbdbd' }
            }
            className="rounded-full w-4 h-4 flex justify-center text-center items-center text-white text-sm"
          >
            3
          </div>
          <div className="text-sm  ml-1" style={{ color: '#333333' }}>
            {t('内容の確認・リクエスト完了')}
          </div>
        </div>
      </div>
    </>
  )
}
