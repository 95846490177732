import { axiosClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'
import { postCheckBeforeApprovalRequest } from 'types/ApiRequest'

type ApiReason =
  | 'device is locked'
  | 'device is already approved'
  | 'approval request already exists'
  | 'invalid email or password'
export const postCheckBeforeApproval = async (
  params: postCheckBeforeApprovalRequest,
) => {
  const res = await axiosClient.post<{
    device_self_id?: string
    device_uuid?: string
    api_reason?: ApiReason
  }>(`${fullUrl()}/enduser_devices/check_before_approval_request`, params)
  return res
}
