export const UADataKeys = [
  'architecture',
  'bitness',
  'brands',
  'mobile',
  'model',
  'platform',
  'platformVersion',
  'uaFullVersion',
  'fullVersionList',
  'wow64',
] as const
