import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from 'presentation/components/Loading'
import { InputStatus } from '../InputStatus'
import { postCreateApproval } from 'api/postCreateApproval'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { InputFormElement } from 'presentation/components/DeviceApprovalRequest/InputFormElement'
import { AttachmentFormElement } from 'presentation/components/DeviceApprovalRequest/AttachmentFormElement'
import { SelectFormElement } from 'presentation/components/DeviceApprovalRequest/SelectFormElement'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { cookies } from 'scripts/cookies'
import { getDeviceUuid } from 'scripts/getDeviceUuid'
import {
  getDeviceApprovalRequestSettingResponse,
  FormState,
  DeviceApprovalRequestItem,
  SelectDeviceApprovalRequestItem,
  DateDeviceApprovalRequestItem,
  TextDeviceApprovalRequestItem,
  FileDeviceApprovalRequestItem,
} from 'types/ApiResponse'

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<InputStatus>>
  status: 'inputIdentity' | 'submit'
  deviceSelfId: string | undefined
  ipAddress: string | undefined
  deviceApprovalRequestSetting: getDeviceApprovalRequestSettingResponse | null
}

export const InputUserIdentity = ({
  setStatus,
  status,
  deviceSelfId,
  ipAddress,
  deviceApprovalRequestSetting,
}: Props) => {
  const [subdomain, setSubdomain] = useState('')
  const [open] = useSnackbar()
  const [errors, setErrors] = useState<{ [x: string]: { message: string } }>({})
  const [deviceId, setDeviceId] = useState('')
  const [formState, setFormState] = useState<FormState>({} as FormState)
  const [postLoading, setPostLoading] = useState(false)
  const { t } = useTranslation()

  const createInitialFormState = (
    response: getDeviceApprovalRequestSettingResponse,
  ): FormState => {
    const initialState = Object.entries(
      response.device_approval_request_form_setting,
    ).reduce((acc, [key, value]) => {
      let fieldValue: any
      if (isFileDeviceApprovalRequestItem(value)) {
        fieldValue = null
      } else if (isDateDeviceApprovalRequestItem(value)) {
      } else if (isTextDeviceApprovalRequestItem(value)) {
      } else if (isSelectDeviceApprovalRequestItem(value)) {
        fieldValue = '' // 「選択してください」の値を入れる
      }
      acc[key] = fieldValue
      return acc
    }, {} as Record<string, any>)

    // checked属性を追加
    initialState['checked'] = false

    return initialState as FormState
  }

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    field_type: string,
  ) => {
    const { name, value } = event.target

    if (field_type === 'select') {
      const selectElement = event.target as HTMLSelectElement
      const selectedValue = selectElement.value
      // selectedValueを使用した処理...
      setFormState((prevState) => ({
        ...prevState,
        [name]: selectedValue,
      }))
    } else if (field_type === 'file') {
      // ファイル入力の場合の処理
      // event.targetがHTMLInputElementであることをTypeScriptに伝える
      const input = event.target as HTMLInputElement
      const file = input.files && input.files[0] ? input.files[0] : null
      setFormState((prevState) => ({
        ...prevState,
        attachment: file,
      }))
    } else {
      // その他のフィールドの処理
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleChangeDirect = (
    name: string,
    value: boolean,
    fieldType: string,
  ) => {
    if (fieldType != 'checkbox') return false
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    ;(async () => {
      // HACK: useEffectはwindow見つからないことはないはず。
      const subdomain = getSubdomain(window.location.host)
      setSubdomain(subdomain)
    })()
  })

  // サブドメインが取れたら、親からprops経由でdeviceApprovalRequestSettingが届く
  // そのタイミングで初期値を設定する
  useEffect(() => {
    ;(async () => {
      if (deviceApprovalRequestSetting) {
        const initialState = createInitialFormState(
          deviceApprovalRequestSetting,
        )
        setFormState(initialState)
      }

      const deviceUuid = getDeviceUuid()
      cookies.setDeviceUuidToCookie(deviceUuid)
      setDeviceId(deviceUuid)
    })()
  }, [subdomain])

  const validateForm = (
    formState: FormState,
    settings: getDeviceApprovalRequestSettingResponse,
  ) => {
    const newErrors: { [key: string]: { message: string } } = {}

    Object.values(settings.device_approval_request_form_setting).forEach(
      (setting) => {
        // field_nameをFormStateのキー型に変換
        const formStateKey = setting.field_name
        const value = formState[formStateKey]

        // フォームが非表示の場合はスキップ
        if (!setting.visible) return

        // 必須チェック
        if (setting.required && !value) {
          newErrors[formStateKey] = { message: `${setting.label}は必須です` }
        }

        // 文字列長のチェック
        if (
          isTextDeviceApprovalRequestItem(setting) &&
          typeof value === 'string' &&
          ((setting.minimum_length && value.length < setting.minimum_length) ||
            (setting.maximum_length && value.length > setting.maximum_length))
        ) {
          newErrors[formStateKey] = {
            message: `${setting.label}は${setting.minimum_length}文字~${setting.maximum_length}文字の間で入力してください`,
          }
        }

        // 正規表現チェック
        if (
          (isDateDeviceApprovalRequestItem(setting) ||
            isTextDeviceApprovalRequestItem(setting)) &&
          typeof value === 'string' &&
          setting.regex &&
          value &&
          !new RegExp(setting.regex).test(value)
        ) {
          newErrors[formStateKey] = {
            message: `${setting.label}の形式が不正です`,
          }
        }

        // 選択肢チェック
        if (
          isSelectDeviceApprovalRequestItem(setting) &&
          typeof value === 'string' &&
          setting.options &&
          !setting.options.some(
            (option: { value: string; label: string }) =>
              option.value === value,
          )
        ) {
          newErrors[formStateKey] = {
            message: `${setting.label}の値が不正です`,
          }
        }
      },
    )
    if (!formState.checked) {
      newErrors['checked'] = { message: 'チェックボックスは必須です' }
    }
    return newErrors
  }

  const handleClickValidate = () => {
    if (!deviceApprovalRequestSetting) return
    const newErrors = validateForm(formState, deviceApprovalRequestSetting)
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      setStatus('submit')
    }
  }

  const handleClickSubmit = async () => {
    if (typeof ipAddress === 'undefined') {
      open('LOCKEDサポート担当までご連絡ください。')
    }

    setPostLoading(true)
    const { status, data } = await postCreateApproval(
      {
        subdomain,
        enduser_device_id: deviceSelfId,
        ip_address: ipAddress,
      },
      formState,
    )
    if (status === 200) {
      setStatus('success')
    } else if (data.reason === 'approval request already exists') {
      open('承認リクエストがすでに存在しています')
    } else if (data.reason === 'device is already approved') {
      open('この端末はすでに承認されています。')
    } else if (data.reason === 'device is locked') {
      open('この端末は凍結されています。管理者に確認をお願いします')
    } else if (data.reason === 'form_input_values are invalid') {
      open('入力値の形式が正しくありません。確認して再度入力をお願いします。')
    } else {
      open('LOCKEDサポート担当までご連絡ください。')
    }
    setPostLoading(false)
  }
  const handleClickCancel = () => {
    setStatus('inputIdentity')
  }

  const isSelectDeviceApprovalRequestItem = (
    item: DeviceApprovalRequestItem,
  ): item is SelectDeviceApprovalRequestItem => item.field_type === 'select'

  const isDateDeviceApprovalRequestItem = (
    item: DeviceApprovalRequestItem,
  ): item is DateDeviceApprovalRequestItem => item.field_type === 'date'

  const isTextDeviceApprovalRequestItem = (
    item: DeviceApprovalRequestItem,
  ): item is TextDeviceApprovalRequestItem => item.field_type === 'text'

  const isFileDeviceApprovalRequestItem = (
    item: DeviceApprovalRequestItem,
  ): item is FileDeviceApprovalRequestItem => item.field_type === 'file'

  console.log('deviceApprovalRequestSetting', deviceApprovalRequestSetting)
  return (
    <>
      {deviceApprovalRequestSetting &&
        deviceApprovalRequestSetting.device_approval_request_form_setting &&
        Object.entries(
          deviceApprovalRequestSetting.device_approval_request_form_setting,
        )
          .sort((a, b) => {
            return a[1].sort_index - b[1].sort_index
          })
          .map(([key, setting]) => {
            type BackendKey =
              keyof getDeviceApprovalRequestSettingResponse['device_approval_request_form_setting']
            const backendKey = key as BackendKey
            const commonProps = {
              deviceApprovalRequestSetting,
              inputStatus: status,
              label: setting.label,
              backend_key: backendKey,
              fieldName: setting.field_name,
              handleChange,
              errors,
            }

            if (isTextDeviceApprovalRequestItem(setting)) {
              const value = formState[setting.field_name] as string
              return (
                <InputFormElement
                  {...commonProps}
                  placeholder={setting.placeholder}
                  value={value}
                />
              )
            } else if (isDateDeviceApprovalRequestItem(setting)) {
              const value = formState[setting.field_name] as string
              return (
                <InputFormElement
                  {...commonProps}
                  value={value}
                  placeholder={setting.placeholder}
                />
              )
            } else if (isFileDeviceApprovalRequestItem(setting)) {
              const value = formState[setting.field_name] as File
              return (
                <AttachmentFormElement {...commonProps} attachment={value} />
              )
            } else if (isSelectDeviceApprovalRequestItem(setting)) {
              const value = formState[setting.field_name] as string
              return <SelectFormElement {...commonProps} value={value} />
            }
          })}
      <div className="flex items-center w-full px-6">
        <input
          type="checkbox"
          name="checked"
          data-test="checkbox"
          checked={formState.checked}
          onChange={(e) => handleChange(e, 'checkbox')}
          disabled={status === 'submit'}
        />
        <p
          className="text-xs ml-2"
          style={{ color: '#333333', cursor: 'pointer' }}
          onClick={() =>
            handleChangeDirect('checked', !formState.checked, 'checkbox')
          }
        >
          {t('記載した内容に虚偽がない')}
        </p>
      </div>
      <div className="w-full px-6  pb-3">
        <p className="text-xs text-red-600">{errors?.checked?.message}</p>
      </div>
      {status === 'inputIdentity' ? (
        <>
          <div className="w-full px-6 mt-8">
            <button
              className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
              data-test="checkInput"
              onClick={handleClickValidate}
            >
              {t('内容の確認にすすむ')}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="w-full px-6 mt-7">
            <button
              className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75 mt-4"
              data-test="submit"
              onClick={handleClickSubmit}
              disabled={postLoading}
            >
              {postLoading ? (
                <div
                  className="flex justify-center p-4"
                  style={{ width: '100%' }}
                >
                  <Loading width={20} height={20}></Loading>
                </div>
              ) : (
                <>{t('送信する')}</>
              )}
            </button>
          </div>
          <div className="w-full px-6 mt-4">
            <button
              className="h-12 bg-white border-black border font-semibold w-full rounded text-black hover:opacity-75 hover:bg-gray-100"
              data-test="submit"
              onClick={handleClickCancel}
            >
              {t('修正する')}
            </button>
          </div>
        </>
      )}
      <div className="w-full px-6 mb-3 mt-4">
        <div
          className="flex items-center justify-center  w-full"
          style={{
            background: '#F2F2F2',
            borderRadius: '3px',
            padding: '2px 6px 2px 4px',
          }}
        >
          <span
            style={{
              fontWeight: 500,
              fontSize: '11px',
              lineHeight: '156%',
              letterSpacing: '0.04em',
              color: '#4F4F4F',
              marginRight: '4px',
            }}
          >
            {t('端末ID')}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '156%',
              letterSpacing: '0.04em',
              color: '#2F80ED',
            }}
          >
            {deviceId}
          </span>
        </div>
      </div>
    </>
  )
}
