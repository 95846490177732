import React from 'react'
import {
  getDeviceApprovalRequestSettingResponse,
  FormState,
} from 'types/ApiResponse'
import { useTranslation } from 'react-i18next'

interface Props {
  deviceApprovalRequestSetting: getDeviceApprovalRequestSettingResponse
  inputStatus: string // statusが取りうる値を列挙
  label: string
  backend_key: keyof getDeviceApprovalRequestSettingResponse['device_approval_request_form_setting']
  fieldName: keyof FormState
  placeholder: string
  value: string
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    field_name: string,
  ) => void
  errors: {
    [key: string]: {
      message?: string
    }
  }
}
export const InputFormElement = ({
  deviceApprovalRequestSetting,
  inputStatus,
  label,
  backend_key,
  fieldName,
  placeholder,
  value,
  handleChange,
  errors,
}: Props) => {
  const { t } = useTranslation()

  const formSetting =
    deviceApprovalRequestSetting?.device_approval_request_form_setting

  if (
    formSetting?.[backend_key]?.field_type !== 'text' &&
    formSetting?.[backend_key]?.field_type !== 'date'
  ) {
    return <></>
  }

  if (
    formSetting?.[backend_key as keyof typeof formSetting]?.visible !== true
  ) {
    return <></>
  }

  return (
    <div className="w-full px-6 pb-3">
      <p className="text-xs font-semibold text-gray-700 pb-1">
        {t(label)}{' '}
        {formSetting?.[backend_key]?.required ? `(${t('必須')})` : <></>}
      </p>
      {inputStatus === 'inputIdentity' ? (
        <>
          <input
            className="rounded px-2 w-full h-8 border border-black"
            data-test={fieldName}
            type="text"
            name={fieldName as string}
            value={value}
            onChange={(e) =>
              handleChange(e, formSetting?.[backend_key]?.field_type)
            }
            placeholder={placeholder}
          />
          <p className="text-xs text-red-600">{errors?.[fieldName]?.message}</p>
        </>
      ) : (
        <p className="text-xs ml-3">{value}</p>
      )}
    </div>
  )
}
