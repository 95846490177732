import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from 'presentation/components/Loading'
import { InputStatus } from '../InputStatus'
import { cookies } from 'scripts/cookies'
import { postCheckBeforeApproval } from 'api/postCheckBeforeApproval'
import { getFingerprintAndHash } from 'scripts/getFingerprintAndHash'
import { Navigator } from 'types/window'
import { FingerPrintHash } from 'types/FingerPrint'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { UADataKeys } from 'scripts/UADataKeys'
import { getDeviceUuid } from 'scripts/getDeviceUuid'

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<InputStatus>>
  setDeviceSelfId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const InputUserEmail = ({ setStatus, setDeviceSelfId }: Props) => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [open] = useSnackbar()
  const [userAgent, setUserAgent] = useState('')
  const [fingerprintHash, setFingerprintHash] = useState<FingerPrintHash>('')
  const [postLoading, setPostLoading] = useState(false)
  const [lockedDeviceUuid, setLockedDeviceUuid] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    const fingerprintHash = cookies.get('_locked_hash')
    setFingerprintHash(fingerprintHash ?? '')
    setUserAgent(navigator.userAgent)
    setLockedDeviceUuid(getDeviceUuid())
  }, [])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value)
        break
      case 'pass':
        setPass(event.target.value)
        break
      default:
        break
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }
  const handleClick = async () => {
    setPostLoading(true)
    const VALIDATE = !pass || !email || email.length > 256
    if (VALIDATE) {
      open('入力内容をご確認の上、もう一度お試しください', 'wrongInputSnackbar')
      setPostLoading(false)
      return
    }

    const ua = await (
      navigator as Navigator
    ).userAgentData?.getHighEntropyValues(UADataKeys)

    const { status, data } = await postCheckBeforeApproval({
      device_uuid: lockedDeviceUuid ?? '',
      email,
      fingerprint_hash: fingerprintHash,
      password: pass,
      user_agent: userAgent,
      client_hint: {
        platform: ua?.platform ?? '',
        platform_version: ua?.platformVersion ?? '',
        brands: ua?.brands,
      },
    })
    if (status === 200) {
      if (data.device_uuid) {
        cookies.setDeviceUuidToCookie(data.device_uuid)
      }
      setDeviceSelfId(data.device_self_id)
      setStatus('inputIdentity')
    } else if (data.api_reason === 'approval request already exists') {
      open('承認リクエストがすでに存在しています')
    } else if (data.api_reason === 'device is already approved') {
      open('この端末はすでに承認されています。')
    } else if (data.api_reason === 'device is locked') {
      open('この端末は凍結されています。管理者に確認をお願いします')
    } else if (data.api_reason === 'invalid email or password') {
      open('入力内容をご確認の上、もう一度お試しください')
    } else {
      open('LOCKEDサポート担当までご連絡ください。')
    }
    setPostLoading(false)
  }
  return (
    <>
      <div className="w-full px-6">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('メールアドレス')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="email"
          type="text"
          name="email"
          value={email}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="w-full px-6 pt-4">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('パスワード')}
        </p>
        <input
          className="rounded px-2 w-full h-8 border border-black"
          data-test="pass"
          type="password"
          name="pass"
          value={pass}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="w-full px-6 mt-8">
        <button
          className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="submit"
          onClick={handleClick}
          disabled={postLoading}
        >
          {postLoading ? (
            <div className="flex justify-center p-4" style={{ width: '100%' }}>
              <Loading width={20} height={20}></Loading>
            </div>
          ) : (
            <>{t('次へ')}</>
          )}
        </button>
      </div>
    </>
  )
}
