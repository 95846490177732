import { axiosFormDataClient } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'
import { FormState } from 'types/ApiResponse'

type ApiReason =
  | 'approval request already exists'
  | 'device is locked'
  | 'device is already approved'
  | 'form_input_values are invalid'

const toSnakeCase = (str: string): string =>
  str.replace(/([A-Z])/g, '_$1').toLowerCase()

export const postCreateApproval = async (
  fixedParams: {
    // 固定パラメータ
    subdomain: string
    enduser_device_id: string | undefined
    ip_address: string | undefined
  },
  formState: FormState, // フォームの状態
) => {
  const formData = new FormData()

  // 固定フィールドの追加
  formData.append('subdomain', fixedParams.subdomain)
  formData.append('enduser_device_id', fixedParams.enduser_device_id ?? '')
  formData.append('ip_address', fixedParams.ip_address ?? '')

  // formStateから動的にフィールドを追加
  Object.keys(formState).forEach((key) => {
    const value = formState[key]
    const snakeCaseKey = toSnakeCase(key) // API側のために、キーをスネークケースに変換
    if (key === 'attachment' && value instanceof File) {
      formData.append(snakeCaseKey, value)
    } else if (typeof value === 'string' || typeof value === 'boolean') {
      formData.append(snakeCaseKey, value.toString())
    }
    // 他の型に対する処理が必要な場合はここに追加
  })

  try {
    const response = await axiosFormDataClient.post<{ reason?: ApiReason }>(
      `${fullUrl('api')}/enduser_devices/approval_request`,
      formData,
    )
    return response
  } catch (error) {
    console.error('Failed to post approval request:', error)
    throw error
  }
}
